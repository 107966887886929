<template>
  <div class="user-tab-overview">
    <!-- student courses -->
    <v-card class="mb-7">
      <v-card-title> Mis Cursos </v-card-title>

      <v-data-table
        :headers="tableCoursesHeaders"
        :items="$props.groupsData"
        :options="{ itemsPerPage: 5 }"
        :footer-props="{
          'items-per-page-text': 'Cursos por página: ',
          'items-per-page-options': [5, 15, 30, 50, 100],
        }"
      >
        <template v-slot:body="{ items }" v-if="$props.groupsData.length > 0">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.name"
              :active="selectedGroup == item"
              :class="{ active: selectedGroup == item }"
              @click="selectGroup(item)"
            >
              <td>
                <div class="d-flex">
                  <div class="text-no-wrap">
                    <p class="font-weight-medium text--primary mb-n1">
                      {{ item.courseName }}
                    </p>
                    <span class="text-xs text--disabled">{{ item.name }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-center">
                  <div class="text-no-wrap">
                    <p class="font-weight-medium text--primary mb-n1">
                      {{
                        $store.state['app-group'].modalityOptions.filter(el => el.value == item.courseModality)[0].title
                      }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <span>{{ item.students[0].attendancePercentage }}%</span>
                <v-progress-linear
                  height="6"
                  rounded
                  class="project-progress mt-1"
                  :color="resolveUserProgressVariant(item.students[0].attendancePercentage)"
                  :value="item.students[0].attendancePercentage"
                ></v-progress-linear>
              </td>
              <td>
                <span>{{ item.workLoadTotal }}</span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data>Usted no se encuentra matriculado a ningún curso</template>
      </v-data-table>
    </v-card>

    <!-- activity timeline -->
    <v-expand-transition>
      <view-subjects
        :selectedGroup="selectedGroup"
        :studentData="$props.userData"
        :title="selectedGroup ? selectedGroup.name + ' - ' + selectedGroup.courseName : ''"
      ></view-subjects>
    </v-expand-transition>
  </div>
</template>

<script>
import store from '@/store'
import { mdiDotsVertical, mdiEye, mdiFilePdf } from '@mdi/js'
import { onUnmounted, ref } from 'vue'
import groupStoreModule from '../../../group/groupStoreModule'

import ViewSubjects from './ViewSubjects'

export default {
  components: {
    ViewSubjects,
  },
  props: {
    groupsData: {
      type: Array,
    },
    userData: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const appGroup = 'app-group'

    // Register module
    if (!store.hasModule(appGroup)) store.registerModule(appGroup, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appGroup)) store.unregisterModule(appGroup)
    })

    const selectedGroup = ref({})

    const dialogs = ref({
      loading: false,
      attendance: false,
    })

    const tableCoursesHeaders = [
      {
        text: 'Curso',
        value: 'courseName',
        sortable: false,
      },
      { text: 'Modalidad', value: 'courseModality', sortable: false },
      { text: 'Asistido', value: 'attendancePercentage', sortable: false },
      { text: 'Carga Horaria', value: 'workLoadTotal', sortable: false },
    ]

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    const selectGroup = group => {
      if (selectedGroup.value.name == group.name) {
        selectedGroup.value = {}
      } else {
        selectedGroup.value = group
      }
    }

    return {
      selectGroup,
      selectedGroup,
      tableCoursesHeaders,
      dialogs,
      resolveUserProgressVariant,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
